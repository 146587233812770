import { api } from "../utils/api";
import { EmailVerify, Register } from "../types/auth";
import ApiConfig from "../utils/apiconfig";
import { clearItem, getItem, setItem } from "./storage";
import {
  ACCESS_TOKEN,
  INITIAL_PROFILE,
  STORAGE_TOKEN,
  USER_PROFILE,
} from "../constants";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import axios from "axios";

export const GetOTP = (data: EmailVerify) => {
  return api.post(ApiConfig.auth.getOTP, data);
};

export const VerifyOTP = (data: EmailVerify) => {
  return api.post(ApiConfig.auth.verifyOTP, data);
};

export const GetChat = (userId: number) => {
  return api.get(ApiConfig.auth.chatGet.replace("%d", userId.toString()));
};

export const PostChat = (data: any) => {
  return api.post(ApiConfig.auth.chatPost, data);
};

export const SetPatientID = ({
  email,
  patientId,
}: {
  email: string;
  patientId: number;
}) => {
  return api.post(ApiConfig.auth.setPatientId, {
    emailId: email,
    patientID: patientId,
  });
};

export const GetPhoneOTP = ({
  countrycode,
  phone,
  tenantId,
}: {
  countrycode: number;
  phone: number | undefined;
  tenantId: number | undefined;
}) => {
  return api.get(ApiConfig.auth.getPhoneOTP, {
    params: {
      phoneNo: phone,
      countrycode: countrycode,
      referrerUrl: "",
      deepLinks: "",
      tenantid: tenantId,
    },
  });
};

export const GetTenantId = ({
  countrycode,
  phone,
}: {
  countrycode: number;
  phone: number | undefined;
}) => {
  return api.get(ApiConfig.auth.getTenant, {
    params: {
      phoneNo: phone,
      countrycode: countrycode,
    },
  });
};

interface VerifyOTPRes {
  user: any;
  token: any;
}
interface LogoutAuth {
  expireUrl: any;
  token: any;
}

export const VerifyPhoneOTP = (data: any) => {
  return api.post<VerifyOTPRes>(ApiConfig.auth.verifyPhoneOTP, data);
};

export const ConsentStatus = (data: any) => {
  return api.get(
    ApiConfig.auth.getConsentStatus.replace(
      "%d",
      data?.data?.user?.patientProfile?.id
    ),
    {
      headers: {
        auth_token: getItem("ACCESS_TOKEN")?.accessToken,
        phone_no: getItem("USER_PROFILE")?.phoneNo,
        client: "patient_android",
      },
    }
  );
};

export const UpdateConsent = (data: any) => {
  return api.put(ApiConfig.auth.updateConsent, data);
};

export const verifyPhoneOTPGetProfiles = (data: any) => {
  return api.post<Array<VerifyOTPRes>>(
    ApiConfig.auth.verifyPhoneOTPGetProfiles,
    data
  );
};

export const RegisterSource = async (data: any) => {
  return api.post(ApiConfig.auth.registerSource, data);
};

export const RegisterUser = async (data: Register) => {
  return api.post(ApiConfig.auth.register, data);
};

export const GetProfile = (): any => {
  return getItem("USER_PROFILE");
};

export const getLatestProfile = (id: any): any => {
  return api.get(ApiConfig.patient.profile.replace("%d", id?.toString()));
};

export const LogoutUser = (): any => {
  let tok = getItem("ACCESS_TOKEN");
  api.post<LogoutAuth>(ApiConfig.auth.expireToken, tok);

  // delete and redirect
  clearItem(USER_PROFILE);
  clearItem(ACCESS_TOKEN);
  clearItem(INITIAL_PROFILE);
  clearItem(STORAGE_TOKEN);

  localStorage.clear();
  window.location.href = "/login";
};

export const isTokenExpired = (): boolean => {
  const tokens = getItem("ACCESS_TOKEN");
  const token = tokens?.accessToken;
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  var finalToken = JSON.parse(jsonPayload);

  if (finalToken) {
    const expiry = finalToken.exp;
    const now = new Date();
    return now.getTime() > expiry * 1000;
  }
  return false;
};

export const GetAuthHeaders = async () => {
  const h = getItem("ACCESS_TOKEN");
  if (h === undefined || h === null) {
    return {
      client: "navigo",
    };
  }
  if (isTokenExpired()) {
    await RefreshToken();
  }
  const authHeaders = {
    auth_token: getItem("ACCESS_TOKEN")?.accessToken,
    phone_no: getItem("USER_PROFILE")?.phoneNo,
    client: "patient_android",
  };

  if (
    authHeaders.phone_no === undefined ||
    authHeaders.auth_token === undefined
  ) {
    delete authHeaders.phone_no;
    delete authHeaders.auth_token;
  }

  return authHeaders;
};

export const IsAuthenticated = async (): Promise<boolean> => {
  const profile = getItem("USER_PROFILE");
  const tokens = getItem("ACCESS_TOKEN");
  if (profile === null || tokens === null) {
    return false;
  }
  // check if expired and regen or logout
  if (isTokenExpired()) {
    await RefreshToken();
  }
  return true;
};

export const RefreshToken = async () => {
  const tokens = getItem("ACCESS_TOKEN");
  if (tokens !== undefined) {
    var payload = {
      accessToken: tokens?.accessToken,
      expiryToken: tokens?.expiryToken,
    };
    var url = ApiConfig.auth.regenToken;

    try {
      let res = await api.postWithoutToken(url, payload);
      //@ts-ignore
      if (res.data?.accessToken != null) {
        setItem("ACCESS_TOKEN", res.data);
      } else {
        LogoutUser();
        return false;
      }
    } catch {
      LogoutUser();
      return false;
    }
  }
};

export const GetPatientProfile = (args?: any) => {
  const user = GetProfile();
  return api.get(
    ApiConfig.patient.profile.replace("%d", user?.patientProfile?.id)
  );
};

export const UpdateProfile = async (profile: any) => {
  const data = await api.post(ApiConfig.patient.update, profile);
};

export const autoLoginFlow = (phoneNo: string, token: string) => {
  let url =
    ApiConfig.authSSO.autoLogin +
    `/login/authorize?phoneNo=${phoneNo}&idToken=${token}`;
  return axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      phone_no: phoneNo,
      Authorization: `Bearer ${token}`,
    },
  });
};

export const GetRedirectUrl = async (data: any, token: string) => {
  const endPoint = ApiConfig.authSSO.getRedirectUrl;
  return axios.post(endPoint, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const checkSSOUser = (data: any, token: string) => {
  const endPoint = ApiConfig.authSSO.check;
  return axios.post(endPoint, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
