import { useState } from "react";
import { useNavigate } from "@tanstack/react-location";
import { ArrowLeft } from "react-feather";
import { LogoutUser, UpdateConsent } from "../services/auth";
import { getItem } from "../services/storage";

const Consent = () => {
  const navigate = useNavigate();

  const [consent, setConsent] = useState({
    privacyPolicy: true,
    contactPermission: true,
  });

  const isButtonDisabled = !consent.privacyPolicy || !consent.contactPermission;

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConsent({ ...consent, [event.target.name]: event.target.checked });
  };

  const handleAgree = async () => {
    const userProfile = getItem("USER_PROFILE");
    const patientId = userProfile ? userProfile.patientProfile?.id : null;
    const data = {
      source: "navigo",
      patientId: patientId,
    };
    try {
      const response = await UpdateConsent(data);

      if (response) {
        navigate({ to: "/" });
      } else {
        console.error("No valid response received.");
      }
    } catch (error) {
      console.error("Failed to fetch consent status:", error);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            LogoutUser();
            window.location.reload();
          }}
        >
          <ArrowLeft />
        </div>
        <h3 style={{ marginLeft: "4px" }}>Terms and conditions</h3>
      </div>
      <ul style={styles.termsList}>
        <li>
          <strong>1. Privacy Policy:</strong> The healthcare app must have a
          clearly written privacy policy outlining what data is collected from
          the users, how that data is used, and who it is shared with. The
          privacy policy must comply with all relevant privacy laws and
          regulations.
        </li>
        <li>
          <strong>2. User Agreement:</strong> The healthcare app should have a
          user agreement that outlines the terms and conditions of using the
          app, including but not limited to user responsibilities, restrictions,
          disclaimers, and limitations of liability.
        </li>
        <li>
          <strong>3. HIPAA Compliance:</strong> If the healthcare app deals with
          personal health information (PHI), it must comply with the Health
          Insurance Portability and Accountability Act (HIPAA) regulations.
        </li>
      </ul>
      <div style={styles.checkboxContainer}>
        <label style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <input
            type="checkbox"
            name="privacyPolicy"
            checked={consent.privacyPolicy}
            onChange={handleCheckboxChange}
          />
          I acknowledge that I have read and understood the privacy policy and
          terms & conditions.
        </label>

        <label style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <input
            type="checkbox"
            name="contactPermission"
            checked={consent.contactPermission}
            onChange={handleCheckboxChange}
          />
          I authorize Zyla to contact me via SMS / email / phone / WhatsApp or
          any other mode overriding my ‘DND’.
        </label>
      </div>
      <button
        onClick={handleAgree}
        disabled={isButtonDisabled}
        style={{
          ...styles.agreeButton,
          backgroundColor: isButtonDisabled ? "#9ca3af" : "#F97F0F",
          cursor: isButtonDisabled ? "not-allowed" : "pointer",
        }}
      >
        I Agree
      </button>
    </div>
  );
};

export default Consent;

const styles = {
  container: {
    maxWidth: "800px",
    margin: "40px auto",
    padding: "20px",
    fontFamily: "Montserrat",
  },
  header: {
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "20px",
    fontFamily: "Montserrat",
    marginLeft: "4px",
  },
  backIcon: {
    cursor: "pointer",
    marginRight: "10px",
    fontSize: "24px",
  },
  termsList: {
    fontSize: "16px",
    lineHeight: "1.6",
    color: "#333",
    paddingLeft: "20px",
    marginBottom: "20px",
    fontFamily: "Montserrat",
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  agreeButton: {
    width: "100%",
    padding: "12px",
    marginTop: "20px",
    color: "white",
    fontSize: "16px",
    fontWeight: "bold",
    border: "none",
    borderRadius: "6px",
    fontFamily: "Montserrat",
  },
};
