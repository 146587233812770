import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { MantineProvider, Global } from "@mantine/core";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { useState, useEffect } from "react";
import {
  Route,
  Router,
  ReactLocation,
  Navigate,
} from "@tanstack/react-location";
import Index from "./pages/home";
import Login from "./pages/login";
import Signup from "./pages/signup";
import Onboarding from "./pages/onboarding";
import Profile from "./pages/profile";
import Family from "./pages/family";
import Help from "./pages/help";
import Appointments from "./pages/appointments";
import Address from "./pages/address";
import Schedule from "./pages/schedule";
import Reschedule from "./pages/reschedule";
import Rescheduled from "./pages/rescheduled";
import Booking from "./pages/booking";
import BookingConfirmed from "./pages/confirmed-booking";
import BasicDetails from "./pages/basic-details";
import PaymentConfirmed from "./pages/confirmed-payment";
import { IsAuthenticated, LogoutUser } from "./services/auth";
import { v4 as uuidv4 } from "uuid";
import { Buffer } from "buffer";
import { getItem } from "./services/storage";
import Consent from "./pages/consent";

export const queryClient = new QueryClient();

const ProtectedRoute = ({
  component,
}: {
  component: React.ReactElement;
}): JSX.Element => {
  const [authenticated, setAuthenticated] = useState<Boolean>(true);
  useEffect(() => {
    IsAuthenticated().then((b) => {
      setAuthenticated(b);
      if (!b) {
        const profile = getItem("USER_PROFILE");
        const tokens = getItem("ACCESS_TOKEN");
        if (profile != null || tokens != null) {
          LogoutUser();
        }
      }
    });
  }, []);
  return (
    <>
      {authenticated ? (
        component
      ) : (
        <Navigate to={`/login?${window.location.hash.substring(1)}`} />
      )}
    </>
  );
};

const UnprotectedRoute = ({
  component,
}: {
  component: React.ReactElement;
}): JSX.Element => {
  const [authenticated, setAuthenticated] = useState<Boolean>(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    IsAuthenticated().then((b) => {
      setAuthenticated(b);
      setLoading(false);
    });
  }, []);
  return loading ? (
    <></>
  ) : (
    <>{!authenticated ? component : <Navigate to="/" />}</>
  );
};

const reactLocation = new ReactLocation();
const routes: Route[] = [
  {
    path: "/",
    element: <ProtectedRoute component={<Index />} />,
  },
  {
    path: "login",
    element: <UnprotectedRoute component={<Login />} />,
  },
  {
    path: "signup",
    element: <UnprotectedRoute component={<Signup />} />,
  },
  {
    path: "/onboarding",
    element: <UnprotectedRoute component={<Onboarding />} />,
  },
  {
    path: "profile",
    element: <ProtectedRoute component={<Profile />} />,
  },
  {
    path: "/family",
    element: <ProtectedRoute component={<Family />} />,
  },
  {
    path: "/address",
    element: <ProtectedRoute component={<Address />} />,
  },
  {
    path: "/help",
    element: <ProtectedRoute component={<Help />} />,
  },
  {
    path: "/appointments",
    element: <ProtectedRoute component={<Appointments />} />,
  },
  {
    path: "/booking",
    element: <ProtectedRoute component={<Booking />} />,
  },
  {
    path: "/basic-details/:sid",
    element: <ProtectedRoute component={<BasicDetails />} />,
  },
  {
    path: "/schedule/:sid",
    element: <ProtectedRoute component={<Schedule />} />,
  },
  {
    path: "/reschedule/:aid",
    element: <ProtectedRoute component={<Reschedule />} />,
  },
  {
    path: "/rescheduled",
    element: <ProtectedRoute component={<Rescheduled />} />,
  },
  {
    path: "/confirmed",
    element: <ProtectedRoute component={<BookingConfirmed />} />,
  },
  {
    path: "/confirmed-payment",
    element: <ProtectedRoute component={<PaymentConfirmed />} />,
  },
  {
    path: "/healthcheckup",
    element: <ProtectedRoute component={<Index />} />,
  },
  {
    path: "/consultations",
    element: <ProtectedRoute component={<Index />} />,
  },
  {
    path: "/consent",
    element: <ProtectedRoute component={<Consent />} />,
  },
];

function MyGlobalStyles() {
  return (
    <Global
      styles={(theme) => ({
        "*, *::before, *::after": { boxSizing: "border-box" },
        // ...other global styles
        a: {
          textDecoration: "none",
        },
      })}
    />
  );
}

function App() {
  useEffect(() => {
    const nonce = new Buffer(uuidv4()).toString("base64");

    const script = document.createElement("script");

    script.src = `https://desk.zoho.in/portal/api/web/inapp/${
      import.meta.env.VITE_ZOHO_ACC
    }?orgId=${import.meta.env.VITE_ZOHO_ORG}`;
    script.async = true;
    script.nonce = nonce;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const disableRightClick = (event: any) => {
    event.preventDefault();
  };
  return (
    <div onContextMenu={disableRightClick}>
      <MantineProvider
        withNormalizeCSS
        theme={{
          fontFamily: "'Montserrat', sans serif",
          headings: { fontFamily: "'Montserrat', sans serif" },
          colors: {
            // Add your color
            zyla: [
              "#fffde7",
              "#fff9c3",
              "#fff59b",
              "#fcec51",
              "#f9e72e",
              "#F97F0F",
              "#F97F0F",
              "#F97F0F",
              "#F97F0F",
              "#f9800f",
            ],
          },
          primaryColor: "zyla",
        }}
      >
        <MyGlobalStyles />
        <QueryClientProvider client={queryClient}>
          <Router routes={routes} location={reactLocation}></Router>
          <ReactQueryDevtools initialIsOpen={true} />
        </QueryClientProvider>
      </MantineProvider>
    </div>
  );
}

export default App;
